import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  NavItem,
  NavLink,
  Col,
} from "react-bootstrap";
import classnames from "classnames";
import RenderTableSection from "./RenderTableSection";
import RenderAssignmentTableSection from "./RenderAssignmentTableSection";
import RenderAdvTableSection from "./RenderAdvTableSection";
import PropTypes from "prop-types";
import RenderListSection from "./RenderListSection";
import RenderAccordionSection from "./RenderAccordionSection";
import RenderFields from "./RenderFields";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import PopupGA from "./PopupGAForm";
import PopupSearch from "./PopupSearch";
import Loading from "./Loading";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getDataFromApi,
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../services/commonServices";
import {
  SFMUpdateApi,
  allotmentCreateApi,
  allotmentUpdateApi,
  getSimgleAllotmentDetailsApi,
  sampleverificationCreateApi,
  sampleverificationSingleApi,
} from "../../services/api";
import {
  CommonTMRoles,
  isModuelePermission,
  rolesDetails,
} from "../../services/commonFunction";
import { useSelector } from "react-redux";
import DeleteConfirmation from "./DeleteConfirmation";
import RejectPopupModal from "./RejectPopupModal";
import { useTranslation } from "react-i18next";
import RenderTablePreview from "./RenderTableSectionPreview";
import {
  getReferenceData,
  handleJRFCreateOrUpdate,
} from "./commonHandlerFunction/JRFHandlerFunctions";
import {
  handleChecklistBtns,
  handleInwardMainSubmit,
  handleInwardStatusChange,
} from "./commonHandlerFunction/sampleInwardHandlerFunctions";
import GroupAssignmentButtons from "./ShowButtons/GroupAssignmentButtons";
import GroupAssignmentPreviewButtons from "./ShowButtons/GroupAssignmentPreviewButtons";
import SampleInwardButtons from "./ShowButtons/SampleInwardButtons";
import InternalCertificateButtons from "./ShowButtons/InternalCertificateButtons";

import ViewCheckListButtons from "./ShowButtons/ViewCheckListButtons";
import JRFButtons from "./ShowButtons/JRFButtons";
import TestMemoButtons from "./ShowButtons/TestMemoButtons";
import SampleVerificationButtons from "./ShowButtons/SampleVerificationButtons";
import AllotmentButtons from "./ShowButtons/AllotmentButtons";
import {
  changeTestMEmoStatuChange,
  getTestMemoDetails,
  getTestMemoDetailsWithCertificate,
  handleTestMemoDelete,
  handleTestMemoStatusChange,
} from "./commonHandlerFunction/testMemoFunctionHandler";
import RenderAdvtestMemoTableSection from "./RenderAdvtestMemoTableSection";
import { handleVerificationMain } from "./commonHandlerFunction/sampleVerificationHandlerFunctions";
import RenderAdvSFMTableSection from "./RenderAdvSFMTableSection";
import {
  getallFormulaList,
  getSFMDetails,
  handleAllotUpdate,
  handleSFMCreateWithoutVerification,
  handleSFMVerificationMain,
} from "./commonHandlerFunction/sfmHandlerFunctions";
import SFMButtons from "./ShowButtons/SFMButtons";
import {
  changeTestReportStatusChange,
  checkICULRNoAvailibility,
  getCertificateDetailsById,
  handleIntarnalCertificateCreateUpdate,
} from "./commonHandlerFunction/intenralCertificateHandlerFunction";
import { saveAs } from "file-saver";
import OverlayLoading from "./OverlayLoading";
import {
  decryptDataForURL,
  encryptDataForURL,
} from "../../utills/useCryptoUtils";
import RenderTableSetAllManualSection from "./RenderTableSetAllManualSection";
import { isValidPhoneNumber } from "react-phone-number-input";
export const selectUser = (state) => state.user;
export const selectRolePermissions = (state) => state.rolePermissions;

const Forms = ({
  formConfig,
  masterResponse,
  getSampleIdsMasterData,
  searchConfigJson,
  getAssignmentMasterData,
  setTestMemoId,
  testMemoId,
  testReportPrweview,
  isExternalJRF,
  totalSamples,
  isRegularJRF
}) => {
  const { t } = useTranslation();
  const translate = t;
  let user = useSelector(selectUser);
  const session = useSelector((state) => state.session);

  user = session.user;

  let rolePermissions = useSelector(selectRolePermissions);
  rolePermissions = session?.user?.permissions;
  const [activeTab, setActiveTab] = useState("1-0");
  const [formData, setFormData] = useState({});
  const [searchFormData, setSearchFormData] = useState({});
  const [searchFormDataType, setSearchFormDataType] = useState({});
  const [searchFormDataErros, setSearchFormDataErros] = useState({});
  const [isFilterBtnClicked, setIsFilterBtnClicked] = useState(false);

  const [filterIndex, setFilterIndex] = useState(1);

  const [formErrors, setFormErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formConfigState, setFormConfigState] = useState(formConfig);
  const [rowAdded, setRowAdded] = useState(false);
  const [searchby, setSearchby] = useState(false);

  const [response, setResponse] = useState({});
  const [statusCounts, setStatusCounts] = useState([]);
  const [jrfId, setJrfId] = useState();
  const [referenceData, setReferenceData] = useState({});
  const [isValideReferenceNo, setIsValideReferenceNo] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [jrfCreationType, setJrfCreationType] = useState("");
  const [viewOnly, setViewOnly] = useState(false);
  const [pageType, setPageType] = useState("");
  const [remarkText, setRemarkText] = useState("");
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState(false);
  const [action, setAction] = useState("");
  const [actionClicked, setActionClicked] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [inwardBtnchange, setInwardBtnchange] = useState("");
  const [subTableData, setSubTableData] = useState([]);
  const [istavSaveClicked, setIstavSaveClicked] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  ////For Group Assignment Only....
  const [gaData, setGaData] = useState([]);
  const [showModalGA, setShowModalGA] = useState(false);
  const [tabOpen, setTabOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [simpleInwardResponse, setSimpleInwardResponse] = useState([]);
  const [testMemoSetData, setTestMemoSetData] = useState([]);
  const [groupDefaultValue, setGroupDefaultValue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isDisplayNewAddOption, setIsDisplayNewAddOption] = useState(false);
  const [testReportData, setTestReportData] = useState("");
  const [subCommodityOptions, setSubCommodityOptions] = useState([]);
  const [customOptions, setCustomOptions] = useState([]);
  const [isRefrenceNoCalled, setIsRefrenceNoCalled] = useState(true);
  const [isOverlayLoader, setIsOverlayLoader] = useState(false);
  const [allFormulaList, setAllformulaList] = useState([]);
  const [isTestMethods, setIstestMethods] = useState(false);
  const [isValideValue, setIsValideValue] = useState(false);
  let navigate = useNavigate();

  let vieableArr = [
    "view",
    "inward",
    "assignment",
    "checklist",
    "assignment-view",
  ];
  useEffect(() => {
    if (formConfig.listView) {
      setLoading(false);

      getAllListingData();
      getAllStatusCount();
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    if (formConfig?.sections[0]?.moduleType === "allotment") {
      const id = decryptDataForURL(params.get("testMemoId"));
      const view = decryptDataForURL(params.get("view"));

      if (view === "view") {
        setViewOnly(true);
        setAction(view);
      }
      setTestMemoId(id);
      if (id) {
        getTestMemoDetails(
          id,
          setTabOpen,
          setFormData,
          setTestMemoSetData,
          "",
          setIsOverlayLoader
        );
      } else {
        getAllotmentDetails();
      }
    } else if (formConfig?.sections[0]?.moduleType === "sampleverification") {
      const sampleAllotmentId = decryptDataForURL(
        params.get("sampleAllotmentId")
      );
      const view = decryptDataForURL(params.get("view"));
      if (view === "view") {
        setViewOnly(true);
        setAction("View");
      }
      if (sampleAllotmentId) {
        getAllotmentDetails();
      } else {
        getVerificationDetails();
      }
    } else if (formConfig?.sections[0]?.moduleType === "testmemomain") {
      const id = decryptDataForURL(params.get("testMemoId"));
      const view = decryptDataForURL(params.get("view"));
      const status = decryptDataForURL(params.get("status"));
      setPageType(status);
      if (view == "view") {
        setViewOnly(true);
        setAction(view);
      }
      setTestMemoId(id);
      getTestMemoDetails(
        id,
        setTabOpen,
        setFormData,
        setTestMemoSetData,
        "",
        setIsOverlayLoader
      );
    } else if (formConfig?.sections[0]?.moduleType == "internalcertificate") {
      const id = decryptDataForURL(params.get("testMemoId"));
      const editId = decryptDataForURL(params.get("editId"));
      const view = decryptDataForURL(params.get("view"));
      const status = decryptDataForURL(params.get("status"));
      setPageType(status);
      if (view === "view" || user?.role !== "LR") {
        setViewOnly(true);
        setAction(view);
      }
      if (editId) {
        getCertificateDetailsById(
          editId,
          setTabOpen,
          setFormData,
          setTestMemoId,
          "",
          setViewOnly,
          getAssignmentMasterData,
          setIsValideValue,
          setSubTableData
        );
      } else if (id) {
        setTestMemoId(id);
        getTestMemoDetailsWithCertificate(
          id,
          setTabOpen,
          setFormData,
          setTestMemoSetData,
          setTestReportData,
          getAssignmentMasterData,
          setIsValideValue,
          user,
          setCustomOptions,
          setIstestMethods
        );
      }
    } else if (formConfig?.sections[0]?.moduleType == "sfm") {
      const id = decryptDataForURL(params.get("sfmid"));
      const view = decryptDataForURL(params.get("view"));
      if (view === "view") {
        setViewOnly(true);
      }
      getSFMDetails(
        id,
        setFormData,
        setTabOpen,
        setIstavSaveClicked,
        setTestMemoSetData,
        view === "view"
      );
      getallFormulaList(setAllformulaList);
    } else {
      getSingleData();
      if (isExternalJRF) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_ext_contactpersonname:
                user.logged_in_user_info.contact_person_name,
              jrf_ext_contactpersonnumber:
                user.logged_in_user_info.contact_person_number,
              cmp_address:
                user?.logged_in_user_info?.lab_or_branch?.lab_address,
              jrf_lab: user?.logged_in_user_info?.lab_or_branch?.lab_id,
              jrf_contact_person: user.logged_in_user_info.contact_person_name,
              jrf_contact_person_number:
                user.logged_in_user_info.contact_person_number,
            },
          };
        });
      } else {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_contact_person: user.logged_in_user_info.contact_person_name,
              jrf_contact_person_number:
                user.logged_in_user_info.contact_person_number,
              cmp_address:
                isRegularJRF ? user?.logged_in_user_info?.lab_or_branch?.lab_address : user?.logged_in_user_info?.lab_or_branch?.branch_address,
              jrf_lab: isRegularJRF ? user?.logged_in_user_info?.lab_or_branch?.lab_id : "",
            },
          };
        });
      }
    }
  }, []);
  useEffect(() => {
    if (referenceNo !== "") {
      getReferenceData(
        referenceNo,
        setIsValideReferenceNo,
        formData,
        setReferenceData,
        setFormData,
        setSubCommodityOptions,
        setIsRefrenceNoCalled,
        setIsOverlayLoader
      );
    }
  }, [referenceNo]);
  useEffect(() => {
    // Reset rowAdded to false after the component has re-rendered
    setRowAdded(false);
  }, [rowAdded]); // Run this effect whenever rowAdded changes
  useEffect(() => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        0: {
          ...prevFormData[0],
          noOfSamples: totalSamples ? totalSamples.length : 0,
        },
      };
    });
  }, [formData[0]?.smpl_inwrd_No, totalSamples]);
  useEffect(() => {
    if (formConfig?.sections[0]?.moduleType === "internalcertificate") {
      if (
        formData[0]?.ic_is_size_analysis &&
        formData[0]?.ic_is_size_analysis[0]
      ) {
        setTabOpen(true);
      } else {
        setTabOpen(false);
      }
    }
  }, [formData[0]?.ic_is_size_analysis]);

  const getPopupConfirmationCallback = (redirectURL) => {
    if (inwardBtnchange) {
      if (inwardBtnchange === "allotment") {
        handleAllotSubmit();
      } else if (formConfig?.sections[0]?.moduleType === "sampleverification") {
        return;
      } else if (inwardBtnchange === "sendToLab") {
        handleTestMemoStatusChange(
          testMemoId,
          navigate,
          "pending",
          formData,
          setIsOverlayLoader
        );
      } else if (inwardBtnchange === "reassign") {
        handleTestMemoDelete(testMemoId, navigate);
      } else {
        handleInwardStatusChange(
          inwardBtnchange,
          pageType,
          formData,
          navigate,
          jrfId,
          setIsOverlayLoader
        );
      }
    } else if (
      jrfCreationType &&
      formConfig?.sections[0]?.moduleType === "sampleverification"
    ) {
      if (jrfCreationType == "post") {
        handleVerificationMain(
          formData,
          navigate,
          testMemoId,
          setSaveClicked,
          setIsOverlayLoader
        );
      } else {
        navigate("/verificationList");
      }
    } else if (formConfig?.sections[0]?.moduleType === "sfm") {
      if (jrfCreationType === "post") {
        handleSFMVerificationMain(
          formData,
          navigate,
          setLoading,
          setIsOverlayLoader,
          formConfig?.sections[0]
        );
      } else {
        navigate("/SFMList");
      }
    } else if (
      formConfig?.sections[0]?.moduleType === "internalcertificate" ||
      formConfig?.listView?.moduleType === "internalcertificate"
    ) {
      if (jrfCreationType == "save" || jrfCreationType == "post") {
        handleIntarnalCertificateCreateUpdate(
          formData,
          testMemoId,
          navigate,
          jrfCreationType,
          setIsOverlayLoader,
          subTableData,
          user
        );
      } else if (jrfCreationType == "approve") {
        changeTestReportStatusChange(
          decryptDataForURL(localStorage.getItem("icId")),
          navigate,
          "dtm-approved",
          "",
          1,
          getAllListingData,
          setIsPopupOpen,
          setIsOverlayLoader
        );
      } else if (jrfCreationType == "publish") {
        changeTestReportStatusChange(
          formData[0].ic_id,
          navigate,
          "publish",
          "",
          "",
          getAllListingData,
          setIsPopupOpen,
          setIsOverlayLoader
        );
      } else {
        navigate("/testReport");
      }
    } else if (jrfCreationType) {
      handleJRFCreateOrUpdate(
        setSaveClicked,
        formData,
        referenceData,
        jrfId,
        formConfig,
        setJrfId,
        navigate,
        setIsPopupOpen,
        jrfCreationType,
        isExternalJRF,
        setIsOverlayLoader,
        isRegularJRF
      );
    } else {
      navigate(redirectURL);
    }
  };

  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";
    if (inwardBtnchange) {
      switch (inwardBtnchange) {
        case "post":
        case "save":
        case "assign":
        case "assignment":
        case "sendToLab":
        case "allotment":
        case "publish":
        case "approve":
        case "reassign":
          headingMsg = formConfig?.popupMessages[inwardBtnchange]?.headerMsg;
          titleMsg = formConfig?.popupMessages[inwardBtnchange]?.titleMsg;
          break;
        default:
          headingMsg = formConfig?.popupMessages.cancel?.headerMsg;
          titleMsg = formConfig?.popupMessages.cancel?.titleMsg;
          break;
      }
    } else {
      switch (jrfCreationType) {
        case "post":
        case "approve":
        case "publish":
        case "save":
          headingMsg = formConfig?.popupMessages[jrfCreationType]?.headerMsg;
          titleMsg = formConfig?.popupMessages[jrfCreationType]?.titleMsg;
          break;
        default:
          headingMsg = formConfig?.popupMessages.cancel?.headerMsg;
          titleMsg = formConfig?.popupMessages.cancel?.titleMsg;
          break;
      }
    }

    let redirectURL = "";
    if (pageType === "inward" || pageType === "assignment") {
      redirectURL = "/inwardList";
    } else if (formConfig?.sections[0]?.moduleType === "testmemomain") {
      redirectURL = "/testmemoList";
    } else if (formConfig?.sections[0]?.moduleType == "internalcertificate") {
      redirectURL = "/testmemoList";
    } else if (formConfig?.sections[0]?.moduleType == "allotment") {
      redirectURL = "/allotmentList";
    } else {
      redirectURL = "/jrfListing";
    }
    return (
      <DeleteConfirmation
        isOpen={isPopupOpen}
        handleClose={setIsPopupOpen}
        handleConfirm={() => getPopupConfirmationCallback(redirectURL)}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
        saveClicked={saveClicked}
        isOverlayLoader={isOverlayLoader}
      />
    );
  };
  const rejectHandleer = (e) => {
    if (formConfig?.sections[0]?.moduleType === "testmemomain") {
      let isSFM = "";
      if (formData[0].status === "results") {
        isSFM = 1;
      }
      changeTestMEmoStatuChange(
        testMemoId,
        navigate,
        "rejected",
        remarkText,
        setIsOverlayLoader,
        isSFM
      );
    } else if (
      formConfig?.sections[0]?.moduleType === "internalcertificate" ||
      formConfig?.listView?.moduleType === "internalcertificate"
    ) {
      let status = "tm-reject";
      let isRedirect = 0;
      let icId = formData[0].ic_id
        ? formData[0].ic_id
        : decryptDataForURL(localStorage.getItem("icId"));
      if (formData[0].status === "pending" || user?.role === "DTM") {
        status = "dtm-reject";
      }
      changeTestReportStatusChange(
        icId,
        navigate,
        status,
        remarkText,
        isRedirect,
        getAllListingData,
        setIsPopupOpen,
        setIsOverlayLoader
      );
    } else {
      handleChecklistBtns(
        e,
        "reject",
        formData,
        remarkText,
        setSaveClicked,
        formConfig,
        navigate,
        setIsOverlayLoader
      );
    }
  };

  const openRejectModal = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";

    headingMsg = "Confirmation!";
    titleMsg = "Do you really want to reject?";
    return (
      <RejectPopupModal
        isOpen={isRejectPopupOpen}
        setRemarkText={setRemarkText}
        remarkText={remarkText}
        handleClose={setIsRejectPopupOpen}
        handleConfirm={(e) => rejectHandleer(e)}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
      />
    );
  };
  const getAllListingData = async (
    pagination = "",
    fieldName = "",
    sortType = "",
    searchValue = "",
    isClearBtn = ""
  ) => {
    try {
      let endPoint =
        ["LC", "SLC"].includes(user?.role) &&
          formConfig?.listView?.moduleType === "allotment"
          ? formConfig?.apiEndpoints?.readUserWise
          : formConfig?.apiEndpoints?.read;
      if (!endPoint) {
        return;
      }
      let querystring = "";
      if (pagination) {
        querystring += "?page=" + pagination;
      }
      if (fieldName && sortType) {
        querystring += querystring
          ? "&sort_by=" + fieldName
          : "?sort_by=" + fieldName;
        querystring += "&sort_order=" + sortType;
      }
      if (searchValue || searchValue === -1) {
        searchValue = searchValue === -1 ? "" : searchValue;
        querystring += querystring
          ? "&search=" + searchValue
          : "?search=" + searchValue;
      } else if (searchTerm) {
        querystring += querystring
          ? "&search=" + searchTerm
          : "?search=" + searchTerm;
      }
      if (!isClearBtn && searchFormData) {
        querystring = querystring || "?search=";

        for (let index = 1; index <= filterIndex; index++) {
          if (searchFormData?.["fieldWiseFilter_" + index]) {
            let fieldName =
              searchFormData?.["fieldWiseFilter_" + index] +
              "__" +
              searchFormData?.["fieldWiseFilterOption_" + index];
            querystring +=
              "&" +
              fieldName +
              "=" +
              searchFormData?.["fieldWiseFilterValue_" + index];
          }
        }
      }
      setLoadingTable(true);

      const res = await getDataFromApi(endPoint + querystring);
      if (res?.data?.status === 200) {
        setResponse(res.data);
        setLoadingTable(false);
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoadingTable(false);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoadingTable(false);
    }
  };
  const getAllListingDataExports = async () => {
    try {
      let endPoint = formConfig?.apiEndpoints?.exportExcel;
      if (!endPoint) {
        return;
      }
      let querystring = "";
      if (searchTerm) {
        querystring += querystring
          ? "&search=" + searchTerm
          : "?search=" + searchTerm;
      }
      if (searchFormData) {
        querystring = querystring || "?search=";

        for (let index = 1; index <= filterIndex; index++) {
          if (searchFormData?.["fieldWiseFilter_" + index]) {
            let fieldName =
              searchFormData?.["fieldWiseFilter_" + index] +
              "__" +
              searchFormData?.["fieldWiseFilterOption_" + index];
            querystring +=
              "&" +
              fieldName +
              "=" +
              searchFormData?.["fieldWiseFilterValue_" + index];
          }
        }
      }
      setLoadingTable(true);
      let res = await postDataFromApi(endPoint + querystring, "", "", 1);
      if (res?.status === 200) {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const excelFileName = formConfig.listView?.moduleType + ".xlsx"; // Change the extension to .xls if needed
        saveAs(blob, excelFileName);
        setLoadingTable(false);
      } else {
        setLoadingTable(false);
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoadingTable(false);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getAllStatusCount = async () => {
    try {
      const endPoint = formConfig?.apiEndpoints?.statuCount;
      const res = await getDataFromApi(endPoint);
      if (res?.data?.status === 200) {
        setStatusCounts(res.data.data);
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getAllotmentDetails = async () => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    const id = decryptDataForURL(params.get("sampleAllotmentId"));
    let bodyToPass = {
      sa_id: id,
    };
    try {
      let res = await postDataFromApi(getSimgleAllotmentDetailsApi, bodyToPass);
      if (res?.data?.status === 200) {
        let actulaResponse = res.data.data;
        actulaResponse.jrf_commodity =
          actulaResponse?.commodity_detail?.cmd_name;
        actulaResponse.jrf_sub_commodity_name =
          actulaResponse?.sub_commodity?.sub_commodity_name;
        actulaResponse.sv_verifiedby =
          actulaResponse?.sample_allotedto_data?.usr_id;
        actulaResponse.sv_dateofverification =
          actulaResponse?.sa_actualdateofreporting;
        actulaResponse.sv_verifiedName =
          actulaResponse?.sample_allotedto_data?.first_name +
          " " +
          actulaResponse?.sample_allotedto_data?.last_name;
        setFormData({
          0: actulaResponse,
        });
      }
    } catch (error) { }
  };
  const getVerificationDetails = async (id = "", from = "") => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    if (!id) {
      id = decryptDataForURL(params.get("sampleVarificationId"));
    }
    let bodyToPass = {
      sv_id: id,
    };
    try {
      let res = await postDataFromApi(sampleverificationSingleApi, bodyToPass);

      if (res?.data?.status === 200) {
        let actulaResponse = res.data.data;
        actulaResponse.jrf_commodity =
          actulaResponse?.commodity_detail?.cmd_name;
        actulaResponse.jrf_sub_commodity_name =
          actulaResponse?.sub_commodity?.sub_commodity_name;
        actulaResponse.sv_verifiedName = actulaResponse?.sv_verifiedby_name;
        actulaResponse.sa_remarks =
          actulaResponse?.allotment_detail?.sa_remarks;
        actulaResponse.sv_dateofverification =
          actulaResponse?.sv_dateofverification
            ? actulaResponse?.sv_dateofverification
            : actulaResponse?.allotment_detail?.sa_actualdateofreporting;
        const svDetailsfilter = actulaResponse.sv_detail.filter((svLab) => {
          svLab.svd_smplweight = svLab.svd_smplweight.replace("/", "");
          svLab.svd_smplweight = svLab.svd_smplweight.replace(" ", "");
          return true;
        });
        actulaResponse.sv_detail = svDetailsfilter;
        setTestMemoId(actulaResponse?.allotment_detail?.fk_testmemo_id);
        setTabOpen(true);
        setIstavSaveClicked(true);
        if (from) {
          const updatedFormData = { ...formData };
          updatedFormData[0]["sv_detail"] = svDetailsfilter;
          setFormData(updatedFormData);
        } else {
          setFormData({
            0: actulaResponse,
          });
        }
      }
    } catch (error) { }
  };
  const getSingleData = async () => {
    setIsOverlayLoader(true);
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    let status = params.get("status") ? params.get("status") : "";

    status = decryptDataForURL(status).toLowerCase();
    const id = decryptDataForURL(params.get("id"));
    const sampleInwardId = decryptDataForURL(params.get("sampleInwardId"));
    let actioning = decryptDataForURL(params.get("action"));
    if (
      sampleInwardId &&
      (status === "assignment" || status === "assignment-view")
    ) {
      getSampleIdsMasterData(sampleInwardId);
    }

    setAction(actioning);

    setJrfId(id);

    setPageType(status);
    if (status !== "inward") {
      setTabOpen(true);
    }

    if (status === "edit" || vieableArr.includes(status)) {
      if (vieableArr.includes(status)) {
        setViewOnly(true);
      }

      let bodyToPass = {
        jrf_id: id,
        model_name: "jrf_detail",
      };
      try {
        let res = await postDataFromApi(
          formConfig.apiEndpoints.read,
          bodyToPass
        );
        if (res?.data?.status === 200) {
          let options = [];
          if (formConfig?.sections[0]?.moduleType === "jrf") {
            // res.data.jrf.jrf_is_external = res.data.jrf.jrf_is_external ? [] : [true]
          }
          setFormData({
            0: res.data.jrf,
            1: res.data.jrf,
          });
          if (status === "edit") {
            if (
              res.data.jrf.jrf_status !== "saved" &&
              res.data.jrf.jrf_status !== "rejected"
            ) {
              setViewOnly(true);
            }
          }
          if (status === "checklist" && res.data.jrf.jrf_status == "posted") {
            setFormData((prevFormData) => {
              return {
                ...prevFormData,
                0: {
                  ...prevFormData[0],
                  jrf_commodity: res.data.jrf?.jrf_commodity_detail?.cmd_name,
                  jrf_sub_commodity_name:
                    res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_name,
                  jrf_company: res.data.jrf?.jrf_company_detail?.cmp_id,
                  jrf_company_name: res.data.jrf?.jrf_company_detail?.cmp_name,
                  // cmp_address: res.data.jrf?.jrf_branch_detail?.br_address,
                  jrf_lab: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_lab_detail?.lab_name +
                    "-" +
                    res.data.jrf?.jrf_lab_detail?.lab_code
                    : res.data.jrf?.jrf_lab_detail?.lab_id,
                  fk_sub_commodity: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_name
                    : res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_id,
                  jrf_branch_name: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_branch_detail?.br_code
                    : res.data.jrf?.jrf_branch_detail?.br_id,
                  cmp_address: res.data.jrf?.jrf_cmp_address,
                },
                1: {
                  ...prevFormData[1],
                  sampleInwardIdMain: sampleInwardId || "",
                  jrf_checklist: [
                    "Customer Name",
                    "Sample Source",
                    "Seal Number",
                    "Commodity",
                    "Test Method",
                  ],
                },
              };
            });
          } else {
            setFormData((prevFormData) => {
              return {
                ...prevFormData,
                0: {
                  ...prevFormData[0],
                  jrf_commodity: res.data.jrf?.jrf_commodity_detail?.cmd_name,
                  jrf_sub_commodity_name:
                    res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_name,
                  jrf_company: res.data.jrf?.jrf_company_detail?.cmp_id,
                  jrf_company_name: res.data.jrf?.jrf_company_detail?.cmp_name,
                  // cmp_address: res.data.jrf?.jrf_branch_detail?.br_address,
                  jrf_lab: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_lab_detail?.lab_name +
                    "-" +
                    res.data.jrf?.jrf_lab_detail?.lab_code
                    : res.data.jrf?.jrf_lab_detail?.lab_id,
                  fk_sub_commodity: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_name
                    : res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_id,
                  jrf_branch_name: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_branch_detail?.br_code
                    : res.data.jrf?.jrf_branch_detail?.br_id,
                  smpl_status: "To be generated",
                  smpl_inwrd_No: "To be generated",
                  cmp_address: res.data.jrf?.jrf_cmp_address,
                },
                1: {
                  ...prevFormData[1],
                  sampleInwardIdMain: sampleInwardId || "",
                },
              };
            });
          }

          if (formConfig?.sections[0]?.moduleType == "GroupAssignment") {
            getAssignmentMasterData(
              res.data.jrf.jrf_commodity_detail.cmd_id,
              res.data.jrf.jrf_lab_detail.lab_id,
              GetTenantDetails(1, 1) == "TPBPL" ? "parameter" : "group"
            );
          }
          setReferenceData(res.data.jrf.jrf_commodity_detail);
          res.data.jrf.jrf_commodity_detail.sub_commodities.map(
            (singleComm, i) =>
              options.push({
                id: singleComm.sub_cmd_id,
                name: singleComm.sub_cmd_name,
              })
          );
          setSubCommodityOptions(options);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            setLoadingTable(false);
          }, 10);
        }
      } catch (error) {
        setLoading(false);
        setLoadingTable(false);
      }
    }
    if (actioning === "View" || actioning === "Edit") {
      setTabOpen(true);
    }
    setIsOverlayLoader(false);
  };

  //For Reference Number Logic....

  async function referenceLogic(fieldName, value) {
    if (fieldName === "jrf_referenceno") {
      let ref_regex = /^(?=.*\d)(?=.*[A-Za-z])[\dA-Za-z]{16}$/;
      if (GetTenantDetails(1, 1) == "TPBPL") {
        ref_regex = /^\d{2}P\d{4}[A-Z]\d{2}[A-Z]{2}\d{4}$/;
      }

      if (ref_regex.test(value)) {
        setReferenceNo(value);
      } else {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_state: "--",
              jrf_commodity: "--",
              jrf_mode: "--",
            },
          };
        });
      }
    }
  }
  const handleFieldChange = (
    sectionIndex,
    fieldName,
    value,
    type = "",
    isChecked = ""
  ) => {
    console.log('handleFieldChange', value, fieldName)
    if (type) {
      value = isChecked;
    }
    if (fieldName === "jrf_referenceno") {
      value = value ? value.toUpperCase() : "";
      referenceLogic(fieldName, value);
    }
    if (formConfig?.sections[0]?.moduleType == "internalcertificate") {
      if (
        fieldName == "ic_smpldrawnbylab" &&
        (value == "Sample Not Drawn By Laboratory")
      ) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              ic_locationofsmpl: "N/A",
              ic_samplingmethods: "N/A",
              ic_envcondition: "N/A",
              ic_conditionofsmpl: "N/A",
              ic_dos: "N/A",
            },
          };
        });
      }
      else if (
        fieldName == "ic_smpldrawnbylab" &&
        value == "As Per Client"
      ) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              ic_locationofsmpl: "N/A",
              ic_samplingmethods: "N/A",
              ic_envcondition: "N/A",
              ic_conditionofsmpl: "N/A",
              ic_is_mark: "",
              ic_is_seal: "",
              ic_dos: "N/A",
            },
          };
        });
      }
      else if (
        fieldName == "ic_smpldrawnbylab" &&
        (value == "Sample Drawn By Laboratory")
        // (value == "Sample Drawn By Laboratory" || value == "As Per Client")
      ) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              ic_dos: "",
              ic_samplingmethods: "",
            },
          };
        });
        getAssignmentMasterData(
          formData[0].cmd_id,
          formData[0].lab_id,
          "parameter",
          setIstestMethods
        );
      } else if (fieldName == "ic_ulrno") {
        checkICULRNoAvailibility(value, setIsOverlayLoader, setIsValideValue);
      }
    } else if (formConfig?.sections[0]?.moduleType == "sfm") {
      if (fieldName === "sfm_dateanalysisstarted") {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            [sectionIndex]: {
              ...prevFormData[sectionIndex],
              ["sfm_dateanalysiscompleted"]: "",
            },
          };
        });
      }
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [sectionIndex]: {
          ...prevFormData[sectionIndex],
          [fieldName]: value,
        },
      };
    });

    // Clear error message when the user starts typing in the field
    setFormErrors((prevFormErrors) => {
      const newFormErrors = { ...prevFormErrors };
      if (newFormErrors[sectionIndex]) {
        delete newFormErrors[sectionIndex][fieldName];
        if (Object.keys(newFormErrors[sectionIndex]).length === 0) {
          delete newFormErrors[sectionIndex];
        }
      }
      return newFormErrors;
    });
  };

  const handleSubmit = () => {
    setActionClicked(true);

    let errors = {};
    const formDataObject = {};
    Object.entries(formData).forEach(([sectionIndex, fields]) => {
      Object.entries(fields).forEach(([fieldName, fieldValue]) => {
        formDataObject[fieldName] = fieldValue;
      });
    });
    let focusErrName = "";
    formConfigState.sections.forEach((section, sectionIndex) => {
      if (section.type === "tabs") {
        section.tabs.forEach((tab) => {
          if (tab.type === "table") {
            tab.rows.forEach((row, rowIndex) => {
              row.forEach((column, columnIndex) => {
                const { required, validation, pattern, type } = column;
                let value =
                  formData[sectionIndex]?.[tab.label]?.[rowIndex]?.[
                  columnIndex
                  ];
                value = !isNaN(+value) ? String(value) : value;
                if (required && (!value || value === "")) {
                  errors = {
                    ...errors,
                    [sectionIndex]: {
                      ...errors[sectionIndex],
                      [tab.label]: {
                        ...(errors[sectionIndex]?.[tab.label] || {}),
                        [rowIndex]: {
                          ...(errors[sectionIndex]?.[tab.label]?.[rowIndex] ||
                            {}),
                          [columnIndex]: validation
                            ? validation.message
                            : "This field is required",
                        },
                      },
                    },
                  };
                } else if (type === "phone") {
                  if (!isValidPhoneNumber(value)) {
                    errors = {
                      ...errors,
                      [sectionIndex]: {
                        ...errors[sectionIndex],
                        [tab.label]: {
                          ...(errors[sectionIndex]?.[tab.label] || {}),
                          [rowIndex]: {
                            ...(errors[sectionIndex]?.[tab.label]?.[rowIndex] ||
                              {}),
                            [columnIndex]: validation
                              ? validation.message
                              : "This field is required",
                          },
                        },
                      },
                    };
                  }
                } else if (type === "email") {
                  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                  const regex = new RegExp(emailPattern);
                  if (!regex.test(value)) {
                    errors = {
                      ...errors,
                      [sectionIndex]: {
                        ...errors[sectionIndex],
                        [tab.label]: {
                          ...(errors[sectionIndex]?.[tab.label] || {}),
                          [rowIndex]: {
                            ...(errors[sectionIndex]?.[tab.label]?.[rowIndex] ||
                              {}),
                            [columnIndex]: "Enter Valide Email address",
                          },
                        },
                      },
                    };
                  }
                } else if (pattern) {
                  const regex = new RegExp(pattern);
                  if (!regex.test(value)) {
                    errors = {
                      ...errors,
                      [sectionIndex]: {
                        ...errors[sectionIndex],
                        [tab.label]: {
                          ...(errors[sectionIndex]?.[tab.label] || {}),
                          [rowIndex]: {
                            ...(errors[sectionIndex]?.[tab.label]?.[rowIndex] ||
                              {}),
                            [columnIndex]: validation
                              ? validation.message
                              : "This field is required",
                          },
                        },
                      },
                    };
                  }
                }
              });
            });
          } else if (tab.type === "accordion") {
            tab.panels.forEach((panel, panelIndex) => {
              tab.accordionfields.forEach((field, fieldIndex) => {
                const { required, validation, pattern } = field;
                let value = formData[sectionIndex]?.[tab.label]?.[fieldIndex];
                value = !isNaN(+value) ? String(value) : value;
                if (required && (!value || value.trim() === "")) {
                  errors = {
                    ...errors,
                    [sectionIndex]: {
                      ...errors[sectionIndex],
                      [tab.label]: {
                        ...(errors[sectionIndex]?.[tab.label] || {}),
                        [tab.label]: {
                          ...(errors[sectionIndex]?.[tab.label]?.[tab.label] ||
                            {}),
                          [fieldIndex]: validation
                            ? validation.message
                            : "This field is required",
                        },
                      },
                    },
                  };
                } else if (pattern) {
                  const regex = new RegExp(pattern);
                  if (!regex.test(value)) {
                    errors = {
                      ...errors,
                      [sectionIndex]: {
                        ...errors[sectionIndex],
                        [tab.label]: {
                          ...(errors[sectionIndex]?.[tab.label] || {}),
                          [tab.label]: {
                            ...(errors[sectionIndex]?.[tab.label]?.[
                              tab.label
                            ] || {}),
                            [fieldIndex]: validation
                              ? validation.message
                              : "This field is required",
                          },
                        },
                      },
                    };
                  }
                }
              });
            });
          }
        });
      } else {
        let notRequired = [];
        if (formConfig?.sections[0]?.moduleType === "jrf") {
          if (user?.role === "LR") {
            if (isRegularJRF) {
              // notRequired.push('jrf_ext_orgnizationname', 'jrf_ext_address', 'jrf_ext_contactpersonname', 'jrf_ext_contactpersonnumber', 'jrf_ext_contactpersonemail')
            }
          }

        }
        else if (formConfig?.sections[0]?.moduleType === "internalcertificate") {
          if (!formData?.[0]?.ic_is_mark && !formData?.[0]?.ic_is_seal) {
            notRequired.push('ic_mark_and_seal_qualifier')
          }
        }
        section.fields.forEach((field) => {
          let { required, validation, pattern, type } = field;
          let value = formData[sectionIndex]?.[field.name];
          value = typeof value === "string" ? value.trim() : value;
          if (notRequired.includes(field.name)) {
            required = false;
          }
          if (
            required &&
            (!value || value === "") &&
            field.type !== "label" &&
            field.type !== "checkbox" &&
            !field.multiple
          ) {
            if (!focusErrName) {
              focusErrName = field.name;
            }

            errors = {
              ...errors,
              [sectionIndex]: {
                ...errors[sectionIndex],
                [field.label]: validation
                  ? validation.message
                  : "This field is required",
              },
            };
          } else if (type === "phone") {
            if (!isValidPhoneNumber(value)) {
              if (!focusErrName) {
                focusErrName = field.name;
              }

              errors = {
                ...errors,
                [sectionIndex]: {
                  ...errors[sectionIndex],
                  [field.label]: validation
                    ? validation.message
                    : "This field is required",
                },
              };
            }
          }
          else if (type === "email") {
            if (!isValidPhoneNumber(value)) {
              if (!focusErrName) {
                focusErrName = field.name;
              }

              errors = {
                ...errors,
                [sectionIndex]: {
                  ...errors[sectionIndex],
                  [field.label]: validation
                    ? validation.message
                    : "This field is required",
                },
              };
            }
          } else if (pattern) {
            let regex;
            if (type === "tel") {
              regex = /^(?:\+91)?\d{10}$/;
            } else {
              regex = new RegExp(pattern);
            }
            if (!regex.test(value)) {
              if (!focusErrName) {
                focusErrName = field.name;
              }

              errors = {
                ...errors,
                [sectionIndex]: {
                  ...errors[sectionIndex],
                  [field.label]: validation
                    ? validation.message
                    : "This field is required",
                },
              };
            }
          } else if (
            (field.name === "jrf_terms_and_conditions" &&
              (!value || value === "")) ||
            (field.type === "select" &&
              field.multiple &&
              value &&
              value.length === 0)
          ) {
            if (!focusErrName) {
              focusErrName = field.name;
            }
            errors = {
              ...errors,
              [sectionIndex]: {
                ...errors[sectionIndex],
                [field.name]: validation
                  ? validation.message
                  : "This field is required",
              },
            };
          }
        });
      }
    });
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      const errorFieldElement = document.getElementById(`${focusErrName}`);
      if (errorFieldElement) {
        errorFieldElement.focus(); // Focus on the field with the first error
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }); // Scroll to it
      }
      setFormErrors(errors);
      return false;
    }
  };

  const addRow = (tab, sectionIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };
      const section = newFormConfig.sections[sectionIndex];
      if (section.type === "tabs") {
        // Find the active tab
        const activeTabIndex = parseInt(activeTab.split("-")[1]);
        const activeTabData = section.tabs[activeTabIndex];

        // Clone the existing rows of the active tab
        const clonedRows = activeTabData.rows.map((row) => [...row]);

        // Copy properties from the first row and add the new row
        const newRow = clonedRows[0].map((property) => ({ ...property }));
        clonedRows.push(newRow);

        // Update the rows of the active tab
        activeTabData.rows = clonedRows;

        // Set newFormConfig to trigger re-render
        return { ...newFormConfig };
      }
      return newFormConfig;
    });
  };
  const addColumn = (tab, sectionIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };
      const section = newFormConfig.sections[sectionIndex];
      if (section.type === "tabs") {
        // Find the active tab
        const activeTabIndex = parseInt(activeTab.split("-")[1]); // Extract tab index from activeTab
        const activeTabData = section.tabs[activeTabIndex];

        // Clone the existing rows of the active tab
        const clonedRows = activeTabData.rows.map((row) => [...row]);
        const Placeholder = "New Column";

        // Iterate over each row and add a new column (text field) to it
        clonedRows.forEach((row) => {
          // Add an empty text field as a new column to each row
          row.push({ type: "text", value: "", placeholder: Placeholder }); // Here, we assume each column has a 'type' and 'value' property
        });

        // Update the rows of the active tab
        activeTabData.rows = clonedRows;

        // Set newFormConfig to trigger re-render
        return { ...newFormConfig };
      }
      return newFormConfig;
    });
  };

  const deleteRow = (sectionIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };

      // Access the correct section based on sectionIndex
      const section = newFormConfig.sections[sectionIndex];

      // Check if the section type is 'tabs'
      if (section.type === "tabs") {
        // Iterate through the tabs
        section.tabs.forEach((tab) => {
          // Check if the tab type is 'table'
          if (tab.type === "table" || tab.type === "tableadv") {
            // Check if there's more than one row
            if (tab.rows.length > 1) {
              // Remove the last row
              tab.rows.pop();
            }
          }
        });
      }

      return { ...newFormConfig };
    });
  };

  const deleteColumn = (sectionIndex, columnIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };
      const section = newFormConfig.sections[sectionIndex];

      // Find the tab with type 'table'
      const tableTab = section.tabs.find((tab) => tab.type === "table");

      if (tableTab) {
        // Remove the column from the headers array
        tableTab.headers.splice(columnIndex, 1);

        // Remove the corresponding cell from each row
        tableTab.rows.forEach((row) => row.splice(columnIndex, 1));
      }

      return newFormConfig;
    });
  };

  const handleAllSave = () => {
    const existingData = localStorage.getItem("allData");
    let newData = [];
    if (existingData) {
      newData = JSON.parse(existingData);
      if (!Array.isArray(newData)) {
        newData = [];
      }
    }
    newData.push(gaData);
    localStorage.setItem("allData", JSON.stringify(newData));
  };

  const handleCancel = () => {
    localStorage.removeItem("allData");
    navigate("/inwardList");
  };

  const handleSampleVerificationMainSubmit = async () => {
    let payload;
    payload = {
      sample_verification: {
        sv_dateofverification: formData[0].sv_dateofverification,
        fk_commodity_id: formData[0].fkey_commodity,
        sv_verifiedby: formData[0].sv_verifiedby,
        fk_allotmentid: formData[0].sa_id,
        status: "pending",
        tenant: GetTenantDetails(1),
      },
    };
    for (let obj in payload.sample_verification_detail) {
      if (
        payload["sample_verification_detail"][obj] === undefined ||
        !payload["sample_verification_detail"][obj]
      ) {
        let errLabel = "";
        toast.error("Date of Verification" + "  required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
        // }
      }
    }
    setIsOverlayLoader(true);
    let res = await postDataFromApi(sampleverificationCreateApi, payload);
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getVerificationDetails(res.data.data.sv_id);
      const currentURL = window.location.href;
      const newQueryString =
        "?status=" +
        encryptDataForURL("verification") +
        "&sampleVarificationId=" +
        encryptDataForURL(res.data.data.sv_id);
      const spbaseUrl = currentURL.split("?");
      let baseUrl = spbaseUrl[0];
      if (spbaseUrl.length > 2) {
        baseUrl = spbaseUrl[0] + spbaseUrl[1];
      }
      const newURL = baseUrl + newQueryString;
      window.history.replaceState({}, "", newURL);
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
  };

  const handleSFMMainSubmit = async () => {
    handleSubmit();
    let isValidate = handleSubmit();
    if (!isValidate) {
      return false;
    }
    let payload;
    payload = {
      sfm_id: formData[0].sfm_id,
      sfm_data: {
        sfm_expecteddateanalysis: formData[0].sfm_expecteddateanalysis,
        sfm_dateanalysisstarted: formData[0].sfm_dateanalysisstarted,
        sfm_dateanalysiscompleted: formData[0].sfm_dateanalysiscompleted,
        sfm_remarks: formData[0].sfm_remarks,
        sfm_ambient_temp: formData[0].sfm_ambient_temp,
        sfm_borometricpressure: formData[0].sfm_borometricpressure,
        sfm_humidity: formData[0].sfm_humidity,
        sfm_status: "in-process",
        tenant: GetTenantDetails(1),
      },
    };
    setIsOverlayLoader(true);
    let res = await putDataFromApi(SFMUpdateApi, payload);
    if (res.data && res.data.status === 200) {
      // handleAllotUpdate(formData)
      getSFMDetails(
        res.data.data.sfm_id,
        setFormData,
        setTabOpen,
        setIstavSaveClicked,
        setTestMemoSetData
      );
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
  };

  const searchAPI = async (value) => {
    try {
      setSearchTerm(value);
      if (value.length > 2 || value.length < 1) {
        if (value) {
          getAllListingData("", "", "", value);
        } else {
          getAllListingData("", "", "", -1);
        }
      } else {
        setLoading(false);
      }
    } catch { }
  };

  //Filterlogic Written here by sufiyan

  const handleSearchFilter = (e, type = "") => {
    const { name, value } = e.target;
    if (type) {
      setSearchFormDataType((prevFormData) => {
        return {
          ...prevFormData,
          [name]: e.target[e.target.selectedIndex].getAttribute("data-type"),
        };
      });
    }
    setSearchFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  /*
  Create by sufiyan 
  use for clrearing search filter
   
  */
  const clearFilterData = () => {
    setSearchFormData({});
    setSearchFormDataErros({});
    setSearchFormDataType({});
    setFilterIndex(1);
    setIsFilterBtnClicked(false);
    getAllListingData("", "", "", "", 1);
  };

  const handleAllotValidate = (e) => {
    let isValidate = handleSubmit();
    if (!isValidate) {
      return false;
    }
    setInwardBtnchange("allotment");
    setIsPopupOpen(true);
  };

  const handleAllotSubmit = async () => {
    let payload;
    payload = {
      // sa_id: formData[0].sa_id,
      sample_allotment: {
        sa_sampleallottedto: formData[0].sa_sampleallottedto,
        sa_actualdateofreporting: formData[0].sa_actualdateofreporting,
        sa_remarks: formData[0].sa_remarks,
        sa_expdateofresult: formData[0].sa_expdateofresult,
        fk_testmemo_id: testMemoId ? testMemoId : formData[0].fk_testmemo_id,
        fkey_commodity: formData[0].fkey_commodity,
        // status: GetTenantDetails(1, 1) == "TPBPL" ? "completed" : "allotted",
        status: "allotted",
        tenant: GetTenantDetails(1),
      },
    };
    let res;
    setIsOverlayLoader(true);
    if (formData[0].sa_id) {
      payload.sa_id = formData[0].sa_id;
      res = await putDataFromApi(allotmentUpdateApi, payload);
    } else {
      res = await postDataFromApi(allotmentCreateApi, payload);
    }
    if (res.data && res.data.status === 200) {
      if (!formData[0].sa_id && GetTenantDetails(1, 1) == "TPBPL") {
        await handleSFMCreateWithoutVerification(res.data.data);
      }
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate("/allotmentList");
      }, 1000);
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
  };

  const handleBackButtonFunction = () => {
    let redirectURL = "";
    if (pageType === "inward" || pageType === "assignment") {
      redirectURL = "/inwardList";
    } else if (formConfig?.sections[0]?.moduleType === "testmemomain") {
      redirectURL = "/testmemoList";
    } else if (formConfig?.sections[0]?.moduleType == "internalcertificate") {
      redirectURL = "/testmemoList";
    } else if (formConfig?.sections[0]?.moduleType == "allotment") {
      redirectURL = "/allotmentList";
    } else if (formConfig?.sections[0]?.moduleType == "sfm") {
      redirectURL = "/SFMList";
    } else if (formConfig?.sections[0]?.moduleType == "sampleverification") {
      redirectURL = "/verificationList";
    } else {
      redirectURL = "/jrfListing";
    }
    navigate(redirectURL);
  };

  const getCustomCellValues = (cell) => {
    if (formConfig?.sections[0]?.moduleType == "internalcertificate") {
      if (
        [
          "ic_samplingmethods",
          "ic_locationofsmpl",
          "ic_dos",
          "ic_envcondition",
          "ic_conditionofsmpl"
        ].includes(cell.name)
      ) {
        if (
          formData[0]?.ic_smpldrawnbylab === "Sample Not Drawn By Laboratory"
        ) {
          if (cell.name === "ic_envcondition") {
            cell.label = "Environmental Conditions During Sampling";
            cell.label = "Environmental Conditions During Sampling";
          } else if (cell.name === "ic_dos") {
            cell.type = "text";
          } else if (cell.name === "ic_samplingmethods") {
            cell.type = "text";
          }
          cell.required = false;
        }
        else if (formData[0]?.ic_smpldrawnbylab === "As Per Client") {
          cell = {
            ...cell,
            "width": 6,
            "thirdName": "ic_is_dos",
            "firstType": cell.type,
            "thirdType": "checkbox",
            "type": "doubleText",
            "fieldWidth": "75",
            "isShowRadioBefore": true,
          };
          if (cell.name === "ic_dos") {
            cell.name = 'ic_dos'
            cell.thirdName = 'ic_is_dos'
            if (formData?.[0]?.ic_is_dos) {
              cell.firstType = "date"
            }
            else {
              cell.firstType = "label"
            }
          }
          else if (cell.name === "ic_samplingmethods") {
            cell.name = 'ic_samplingmethods'
            cell.thirdName = 'ic_is_samplingmethods'
          }
          else if (cell.name === "ic_locationofsmpl") {
            cell.name = 'ic_locationofsmpl'
            cell.thirdName = 'ic_is_locationofsmpl'
          }
          else if (cell.name === "ic_envcondition") {
            cell.name = 'ic_envcondition'
            cell.thirdName = 'ic_is_envcondition'
          }
          else if (cell.name === "ic_conditionofsmpl") {
            cell.name = 'ic_conditionofsmpl'
            cell.thirdName = 'ic_is_conditionofsmpl'
          }

          cell.required = false;
        } else {
          cell.required = true;
          if (cell.name === "ic_envcondition") {
            cell.label = "Environmental Conditions During Sampling";
            cell.placeholder = "Environmental Conditions During Sampling";
          }
          else if (cell.name === "ic_dos") {
            cell.type = "date";
          }
          else if (cell.name === "ic_samplingmethods") {
            if (!isTestMethods) {
              cell.type = "text";
            } else {
              cell.type = "select";
              cell.options = [];
              cell.multiple = true;
              cell.isCustomOptions = true;
              cell.customOptions = customOptions?.['ic_samplingmethods'] || []
            }
          }
        }
      } else if (cell.name === "ic_ulrno") {
        if (formData[0]?.ic_id || formData[0].nonScopeData) {
          if (formData[0].nonScopeData || !formData[0]?.ic_ulrno) {
            cell.value = "-";
          }
          cell.type = "label";
          cell.required = false;
          cell.pattern = "";
        }
        else {
          cell = {
            "width": 6,
            "name": "ic_ulrno",
            "label": "ULR Number",
            "type": "text",
            "placeholder": "Enter ULR Number",
            "pattern": "^[A-Z]{2}\\d{4,5}\\d{2}\\d{9}$",
            "tooltip": "Before inputting the ULR Number, note its format:TC6456-Lab Complience Code, 24 - Current year,  00000000000- Sequene number(9 digit). Ensure accuracy for precision.",
            "isPatternMessage": true,
            "required": true
          }
        }
      }
      else if (cell.name === "ic_smpldrawnbylab") {
        // if (GetTenantDetails(1, 1) === "TPBPL") {
          cell.options = [
            "Sample Drawn By Laboratory",
            "Sample Not Drawn By Laboratory",
            "As Per Client"
          ]
        // }
      }
      else if (cell.name === "ic_mark_and_seal_qualifier") {
        if (formData?.[0]?.ic_is_mark || formData?.[0]?.ic_is_seal) {
          cell.required = true
        }
        else {
          cell.required = false
        }
      }
    } else if (formConfig?.sections[0]?.moduleType == "jrf") {
      if (cell.name == "jrf_lab") {
        if (user?.role === "LR" && viewOnly && !isExternalJRF) {
          cell.name = "jrf_branch_name";
          cell.label = "Branch Name";
        }
        if (isExternalJRF) {
          cell.disabled = true;
          cell.readOnly = true;
        }
        else if (isRegularJRF) {
          cell.disabled = true;
          cell.readOnly = true;
        }
      } else if (cell.name == "fk_sub_commodity") {
        cell.isCustomOptions = true;
        cell.customOptions = subCommodityOptions;
      } else if (cell.name == "jrf_test_method") {
        // if (GetTenantDetails(1, 1) == "TPBPL") {
        //   cell.options = ["IS", "ISO", "ASTM", "SOP", "IP", "IEC", "DIN"];
        // }
      } else if (cell.name === "jrf_terms_and_conditions") {
        if (GetTenantDetails(1, 1) === "TPBPL") {
          cell.options = [
            "Terms & Conditions: The samples will be retained for a duration of 30 days from the date of certificate issuance. However, for export vessels, the retention period will be extended to 90 days, where applicable. Any grievances or complaints must be duly lodged within a timeframe of 15 days from the date of issuance of the test report. Both parties will adhere to and uphold a set of compliance code and principles in the provision of services. We do not provides statement of conformity, opinions or interpretations concerning the findings rendered in reports.",
          ];
        }
      } else if (cell.name === "jrf_referenceno") {
        if (GetTenantDetails(1, 1) === "TPBPL") {
          cell.pattern = /^\d{2}P\d{4}[A-Z]\d{2}[A-Z]{2}\d{4}$/;
        }
      }
      if (user?.role === "LR" && isRegularJRF) {
        // if (cell.name === "jrf_ext_orgnizationname" || cell.name === "jrf_company_name") {
        //   cell.name = "jrf_company_name";
        //   cell.label = "Organization Name";
        //   cell.placeholder = "Organization Name";
        // }
        // else if (cell.name === "jrf_ext_address" || cell.name === "cmp_address") {
        //   cell.name = "cmp_address";
        //   cell.label = "Organization Address";
        //   cell.placeholder = "Organization Address";
        // }
        // else if (cell.name === "jrf_ext_contactpersonname" || cell.name === "jrf_contact_person") {
        //   cell.name = "jrf_contact_person";
        //   cell.label = "Name of Contact Person";
        //   cell.placeholder = "Name of Contact Person";
        // }
        // else if (cell.name === "jrf_ext_contactpersonnumber" || cell.name === "jrf_contact_person_number") {
        //   cell.name = "jrf_contact_person_number";
        //   cell.label = "Phone No. of Contact Person";
        //   cell.placeholder = "Phone No. of Contact Person";
        // }
      }
    } else if (formConfig?.sections[0]?.moduleType == "sfm") {
      if (cell.name === "sfm_dateanalysiscompleted") {

        cell.pastDate = false;
        cell.minDate = formData[0]?.sample_inward_dos;
      } else if (cell.name === "sfm_dateanalysisstarted") {
        cell.startDate = formData[0].sample_dateofverification
          ? formData[0].sample_dateofverification
          : "";
      } else if (cell.name === "sfm_humidity") {
        if (GetTenantDetails(1, 1) === "TPBPL") {
          cell.label = "Relative humidity";
        }
      }
    } else if (formConfig?.sections[0]?.moduleType == "GroupAssignment") {
      if (cell.name == "cmp_address") {
        if (formData[0]?.jrf_is_external) {
          cell.name = "jrf_ext_address";
          cell.label = "External Customer Address";
        }
      } else if (cell.name == "jrf_company_name") {
        if (formData[0]?.jrf_is_external) {
          cell.name = "jrf_ext_orgnizationname";
          cell.label = "External Customer Name";
        }
      }
    } else if (formConfig?.sections[0]?.moduleType == "inwardChecklist") {
      if (cell.name == "jrf_company_name") {
        if (formData[0]?.jrf_is_external) {
          cell.name = "jrf_ext_orgnizationname";
          cell.label = "External Customer Name";
        }
      } else if (cell.name == "jrf_pkging_condition") {
        if (GetTenantDetails(1, 1) == "TPBPL") {
          cell.options = [
            "Sealed",
            "Unsealed",
            "Contamination",
            "Sign of Damage (Puncture, Leaks)",
            "Intact"
          ];
        }
      }
    } else if (formConfig?.sections[0]?.moduleType == "allotment") {
      if (cell.name === "sa_actualdateofreporting") {
        cell.pastDate = true;
        cell.minDate = formData[0]?.smpl_dos;
      }
    }

    //For temporrary Date field set future and pass
    if (
      [
        "smpl_detail_dos",
        "sa_actualdateofreporting",
        "sa_expdateofresult",
        "sv_dateofverification",
        "sfm_dateanalysisstarted",
        "sfm_dateanalysiscompleted",
        "ic_dos",
        "ic_dateofrecsmpl",
        "ic_dateofanalysis",
      ].includes(cell.name)
    ) {
      if (["sa_expdateofresult", "sfm_dateanalysiscompleted"]) {
        cell.futureDays = 30;
        cell.noLimitation = true;
      } else {
        cell.pastDate = true;
      }
      cell.pastdays = 45;
    }
    return cell;
  };
  ///For Main Submission...
  return !loading ? (
    <form key={"Main-Form"}>
      {/* <form onSubmit={handleSubmit}> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isOverlayLoader && <OverlayLoading />}
      {formConfig.sections?.map((section, sectionIndex) =>
        section.type === "tabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                {section.tabs.map((tab, tabIndex) => {
                  if (tab.type === "tablePreview") {
                    if (
                      (action === "View" || !tab.isSubPreview) &&
                      (!tab.isSubPreview ||
                        [
                          "assigning",
                          "assigned",
                          "tm-created",
                          "sent to lab",
                          "certified",
                        ].includes(formData[0]?.smpl_status))
                    ) {
                      return (
                        <NavItem key={"section-tab" + tabIndex}>
                          <NavLink
                            className={classnames("nav-link tab_header", {
                              active:
                                activeTab === `${sectionIndex}-${tabIndex}`,
                            })}
                            onClick={() =>
                              setActiveTab(`${sectionIndex}-${tabIndex}`)
                            }
                            tabIndex="0"
                            href="#"
                          >
                            {tab.label}
                          </NavLink>
                        </NavItem>
                      );
                    }
                  } else {
                    return (
                      <NavItem key={"section-tab" + tabIndex}>
                        <NavLink
                          className={classnames("nav-link tab_header", {
                            active: activeTab === `${sectionIndex}-${tabIndex}`,
                          })}
                          onClick={() =>
                            setActiveTab(`${sectionIndex}-${tabIndex}`)
                          }
                          tabIndex="0"
                          href="#"
                        >
                          {tab.label}
                        </NavLink>
                      </NavItem>
                    );
                  }
                })}
              </div>
              <div className="tab-content">
                {section.tabs.map((tab, tabIndex) => (
                  <div
                    key={"tab-content" + tabIndex}
                    role="tabpanel"
                    className={classnames("tab-pane", {
                      active: activeTab === `${sectionIndex}-${tabIndex}`,
                    })}
                  >
                    {/* Render tab content here */}
                    <Row>
                      <Col>
                        {tab.type === "table" ? (
                          // tab.headers.length < 5 ? (
                          <span>
                            {
                              <Popup
                                showModal={showModal}
                                setShowModal={setShowModal}
                                tab={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={addRow}
                              />
                            }
                            <RenderTableSection
                              key={`${sectionIndex}-${tabIndex}`}
                              section={tab}
                              sectionIndex={sectionIndex}
                              formData={formData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              addRow={() => {
                                tab.headers.length < 6 ||
                                  pageType === "inward" ||
                                  pageType === "assignment"
                                  ? addRow(tab, sectionIndex)
                                  : setShowModal(true);
                              }}
                              deleteRow={() => deleteRow(sectionIndex)}
                              deleteColumn={(columnIndex) =>
                                deleteColumn(sectionIndex, columnIndex)
                              }
                              setFormData={setFormData}
                              popupMessages={formConfig.popupMessages}
                              pageType={pageType}
                              action={action}
                              masterOptions={masterResponse}
                              actionClicked={actionClicked}
                              setSaveClicked={setSaveClicked}
                              saveClicked={saveClicked}
                              setTableData={setSubTableData}
                              tableData={subTableData}
                              moduleType={formConfig?.sections[0]?.moduleType}
                              pageType2={true}
                              simpleInwardResponse={simpleInwardResponse}
                              setSimpleInwardResponse={setSimpleInwardResponse}
                              groupDefaultValue={groupDefaultValue}
                              testMemoId={testMemoId}
                              getVerificationDetails={getVerificationDetails}
                              getSampleIdsMasterData={getSampleIdsMasterData}
                              setIsOverlayLoader={setIsOverlayLoader}
                              isOverlayLoader={isOverlayLoader}
                            />
                          </span>
                        ) : tab.type === "manualTable" ? (
                          // tab.headers.length < 5 ? (
                          <span>
                            {
                              <Popup
                                showModal={showModal}
                                setShowModal={setShowModal}
                                tab={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={addRow}
                              />
                            }
                            <RenderTableSetAllManualSection
                              key={`${sectionIndex}-${tabIndex}`}
                              section={tab}
                              sectionIndex={sectionIndex}
                              formData={formData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              addRow={() => {
                                tab.headers.length < 6 ||
                                  pageType === "inward" ||
                                  pageType === "assignment"
                                  ? addRow(tab, sectionIndex)
                                  : setShowModal(true);
                              }}
                              deleteRow={() => deleteRow(sectionIndex)}
                              deleteColumn={(columnIndex) =>
                                deleteColumn(sectionIndex, columnIndex)
                              }
                              setFormData={setFormData}
                              popupMessages={formConfig.popupMessages}
                              pageType={pageType}
                              action={action}
                              masterOptions={masterResponse}
                              actionClicked={actionClicked}
                              setSaveClicked={setSaveClicked}
                              saveClicked={saveClicked}
                              setTableData={setSubTableData}
                              tableData={subTableData}
                              moduleType={formConfig?.sections[0]?.moduleType}
                              pageType2={true}
                              simpleInwardResponse={simpleInwardResponse}
                              setSimpleInwardResponse={setSimpleInwardResponse}
                              groupDefaultValue={groupDefaultValue}
                              testMemoId={testMemoId}
                              getVerificationDetails={getVerificationDetails}
                              getSampleIdsMasterData={getSampleIdsMasterData}
                              setIsOverlayLoader={setIsOverlayLoader}
                              isOverlayLoader={isOverlayLoader}
                              viewOnly={viewOnly}
                            />
                          </span>
                        ) : tab.type === "tableadv" ? (
                          <RenderAdvTableSection
                            key={`${sectionIndex}-${tabIndex}`}
                            section={tab}
                            sectionIndex={sectionIndex}
                            formData={formData}
                            handleFieldChange={handleFieldChange}
                            formErrors={formErrors}
                            addRow={() => {
                              true
                                ? addRow(tab, sectionIndex)
                                : setShowModal(true);
                            }}
                            addColumn={() => addColumn(tab, sectionIndex)}
                            deleteRow={() => deleteRow(sectionIndex)}
                            deleteColumn={(columnIndex) =>
                              deleteColumn(sectionIndex, columnIndex)
                            }
                            groupAssignment={tab.groupAssignment}
                            handleAllSave={handleAllSave}
                            handleCancel={handleCancel}
                            gaData={gaData}
                            setGaData={setGaData}
                            showModalGA={showModalGA}
                            setShowModalGA={setShowModalGA}
                            pageType={pageType}
                            actionClicked={actionClicked}
                            setIsOverlayLoader={setIsOverlayLoader}
                          />
                        ) : tab.type === "tablePreview" ? (
                          (action === "View" || !tab.isSubPreview) &&
                          (!tab.isSubPreview ||
                            [
                              "assigning",
                              "assigned",
                              "tm-created",
                              "sent to lab",
                              "certified",
                            ].includes(formData[0]?.smpl_status)) && (
                            <RenderTablePreview
                              key={`${sectionIndex}-${tabIndex}`}
                              section={tab}
                              sectionIndex={sectionIndex}
                              formData={formData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              addRow={() => {
                                tab.headers.length < 6 ||
                                  pageType === "inward" ||
                                  pageType === "assignment"
                                  ? addRow(tab, sectionIndex)
                                  : setShowModal(true);
                              }}
                              deleteRow={() => deleteRow(sectionIndex)}
                              deleteColumn={(columnIndex) =>
                                deleteColumn(sectionIndex, columnIndex)
                              }
                              setFormData={setFormData}
                              popupMessages={formConfig.popupMessages}
                              pageType={pageType}
                              action={action}
                              masterOptions={masterResponse}
                              actionClicked={actionClicked}
                              isSubPreview={tab.isSubPreview}
                              simpleInwardResponse={simpleInwardResponse}
                              setSimpleInwardResponse={setSimpleInwardResponse}
                              tabIndex={tabIndex}
                            />
                          )
                        ) : tab.type === "accordion" ? (
                          <div
                            key={"sectionIndex" + sectionIndex}
                            className="my-2 bg-white"
                          >
                            <Card>
                              <CardBody>
                                <Row>
                                  {tab.fields.map((field, fieldIndex) => {
                                    let isShow = true;

                                    return isShow && <div
                                      key={"Form-Accordion" + fieldIndex}
                                      className={"col-md-" + field.width}
                                    >
                                      <RenderFields
                                        field={field}
                                        sectionIndex={sectionIndex}
                                        fieldIndex={fieldIndex}
                                        formData={formData}
                                        handleFieldChange={handleFieldChange}
                                        formErrors={formErrors}
                                        ///For View Only
                                        viewOnly={viewOnly}
                                        actionClicked={actionClicked}
                                      />
                                    </div>
                                  })}
                                  <RenderAccordionSection
                                    section={tab}
                                    sectionIndex={sectionIndex}
                                    formData={formData}
                                    handleFieldChange={handleFieldChange}
                                    formErrors={formErrors}
                                  />
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        ) : (
                          <div
                            key={"sectionIndex" + sectionIndex}
                            className="my-2 bg-white"
                          >
                            <Card>
                              <CardBody>
                                {/* <CardTitle tag="h5">{section.title}</CardTitle> */}
                                <Row>
                                  {tab.fields.map((field, fieldIndex) => {
                                    let isShow = true;

                                    if (
                                      formConfig?.sections[0]?.moduleType ===
                                      "inwardChecklist"
                                    ) {
                                      if (
                                        field.name === "jrf_sample_condition" &&
                                        GetTenantDetails(1, 1) == "TPBPL"
                                      ) {
                                        isShow = false;
                                      } else if (
                                        field.name == "jrf_pkging_condition"
                                      ) {
                                        if (GetTenantDetails(1, 1) == "TPBPL") {
                                          field.options = [
                                            "Sealed",
                                            "Unsealed",
                                            "Contamination",
                                            "Sign of Damage (Puncture, Leaks)",
                                            "Intact"
                                          ];
                                          // field.hintText = "<ul><li>All information is mutually confidential between TCRC Petrolabs and the customer. Any intention of placing the same in the public domain must be mutually agreed upon in print.</li><li>All proprietary information of TCRC Petrolabs and their customers is mutually agreed upon as confidential.</li><li>Tick (☑) whichever applies.</li></ul>"
                                        }
                                      } else if (
                                        field.name ===
                                        "jrf_vc_term_condition" &&
                                        GetTenantDetails(1, 1) !== "TPBPL"
                                      ) {
                                        isShow = false;
                                      }
                                      if (
                                        field.name ===
                                        "jrf_vc_term_condition" &&
                                        GetTenantDetails(1, 1) !== "TPBPL"
                                      ) {
                                        isShow = false;
                                      }
                                    }
                                    if (field.name === "quantity_received_sample_desc" && GetTenantDetails(1, 1) !== "TPBPL") {
                                      console.log("quantiy");

                                      isShow = false;
                                    }
                                    return (
                                      isShow && (
                                        <div
                                          key={"Form-default" + fieldIndex}
                                          className={"col-md-" + field.width}
                                        >
                                          <RenderFields
                                            field={field}
                                            sectionIndex={sectionIndex}
                                            fieldIndex={fieldIndex}
                                            formData={formData}
                                            handleFieldChange={
                                              handleFieldChange
                                            }
                                            formErrors={formErrors}
                                          />
                                        </div>
                                      )
                                    );
                                  })}
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
              {section.label === "Test Memo" ? (
                <div className="tab_footer">
                  <p>
                    {rolesDetails.map((role, UserIndex) => (
                      <span key={"role-" + UserIndex}>
                        {user?.role === role?.role ? role.label : null}
                      </span>
                    ))}{" "}
                    <br />
                    <br />{" "}
                    <span>
                      {user?.logged_in_user_info?.contact_person_name}
                    </span>
                  </p>
                  {/* <p>
                    Technical Manager <br />
                    <br /> <span>Benjamin Thompson</span>
                  </p> */}
                </div>
              ) : null}
            </div>
          ) : null
        ) : section.type === "testMemoTabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                {testMemoSetData.map((tab, tabIndex) => {
                  return (
                    <React.Fragment key={"tabIndex" + tabIndex}>
                      <NavItem key={"section-tab" + tabIndex}>
                        <NavLink
                          className={classnames("nav-link tab_header", {
                            active: activeTab === `${sectionIndex}-${tabIndex}`,
                          })}
                          onClick={() =>
                            setActiveTab(`${sectionIndex}-${tabIndex}`)
                          }
                          tabIndex="0"
                          href="#"
                        >
                          {"Set " + (tabIndex + 1)}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="tab-content">
                {testMemoSetData.map((tab, tabIndex) => (
                  <div
                    key={"tab-content" + tabIndex}
                    role="tabpanel"
                    className={classnames("tab-pane", {
                      active: activeTab === `${sectionIndex}-${tabIndex}`,
                    })}
                  >
                    <Row>
                      <Col>
                        <RenderAdvtestMemoTableSection
                          key={`${sectionIndex}-${tabIndex}`}
                          section={section}
                          tabIndex={tabIndex}
                          setData={tab}
                          sectionIndex={sectionIndex}
                          formData={formData}
                          handleFieldChange={handleFieldChange}
                          formErrors={formErrors}
                          addRow={() => {
                            true
                              ? addRow(tab, sectionIndex)
                              : setShowModal(true);
                          }}
                          addColumn={() => addColumn(tab, sectionIndex)}
                          deleteRow={() => deleteRow(sectionIndex)}
                          deleteColumn={(columnIndex) =>
                            deleteColumn(sectionIndex, columnIndex)
                          }
                          groupAssignment={tab.groupAssignment}
                          handleAllSave={handleAllSave}
                          handleCancel={handleCancel}
                          gaData={gaData}
                          setGaData={setGaData}
                          showModalGA={showModalGA}
                          setShowModalGA={setShowModalGA}
                          pageType={pageType}
                          actionClicked={actionClicked}
                          setIsOverlayLoader={setIsOverlayLoader}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
              {section.label === "Test Memo" ? (
                <div className="tab_footer">
                  <p>
                    {rolesDetails.map((role, UserIndex) => (
                      <span key={"role-" + UserIndex}>
                        {formData[0]?.tm_created_by?.role === role?.role
                          ? role.label
                          : null}
                      </span>
                    ))}{" "}
                    <br />
                    <br /> <span>{formData[0]?.tm_created_by?.name}</span>
                  </p>
                  <p>
                    {rolesDetails.map((role, UserIndex) => (
                      <span key={"role-" + UserIndex}>
                        {formData[0]?.technical_manager?.role === role?.role
                          ? role.label
                          : null}
                      </span>
                    ))}{" "}
                    <br />
                    <br /> <span>{formData[0]?.technical_manager?.name}</span>
                  </p>
                </div>
              ) : null}
            </div>
          ) : null
        ) : section.type === "sampleAssignmentTabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                <NavItem key={"section-tab"}>
                  <NavLink
                    className={classnames("nav-link tab_header", {
                      active: true,
                    })}
                    tabIndex="0"
                    href="#"
                  >
                    Sample Group and Parameter List
                  </NavLink>
                </NavItem>
              </div>

              {section.tabs.map((tab, tabIndex) => (
                <Row key={"tabIndx" + tabIndex}>
                  <Col>
                    <RenderAssignmentTableSection
                      key={`${sectionIndex}-${tabIndex}`}
                      section={tab}
                      sectionIndex={sectionIndex}
                      formData={formData}
                      handleFieldChange={handleFieldChange}
                      formErrors={formErrors}
                      addRow={() => {
                        tab.headers.length < 6 ||
                          pageType === "inward" ||
                          pageType === "assignment"
                          ? addRow(tab, sectionIndex)
                          : setShowModal(true);
                      }}
                      deleteRow={() => deleteRow(sectionIndex)}
                      deleteColumn={(columnIndex) =>
                        deleteColumn(sectionIndex, columnIndex)
                      }
                      setFormData={setFormData}
                      popupMessages={formConfig.popupMessages}
                      pageType={pageType}
                      action={action}
                      masterOptions={masterResponse}
                      actionClicked={actionClicked}
                      setSaveClicked={setSaveClicked}
                      saveClicked={saveClicked}
                      setTableData={setSubTableData}
                      tableData={subTableData}
                      moduleType={formConfig?.sections[0]?.moduleType}
                      pageType2={true}
                      simpleInwardResponse={simpleInwardResponse}
                      setSimpleInwardResponse={setSimpleInwardResponse}
                      groupDefaultValue={groupDefaultValue}
                      testMemoId={testMemoId}
                      getVerificationDetails={getVerificationDetails}
                      getSampleIdsMasterData={getSampleIdsMasterData}
                      getAssignmentMasterData={getAssignmentMasterData}
                      isDisplayNewAddOption={isDisplayNewAddOption}
                      setIsDisplayNewAddOption={setIsDisplayNewAddOption}
                      setIsOverlayLoader={setIsOverlayLoader}
                      isOverlayLoader={isOverlayLoader}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          ) : null
        ) : section.type === "SFMTabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                {testMemoSetData.map((tab, tabIndex) => {
                  return (
                    <React.Fragment key={"tabIndex" + tabIndex}>
                      <NavItem key={"section-tab" + tabIndex}>
                        <NavLink
                          className={classnames("nav-link tab_header", {
                            active: activeTab === `${sectionIndex}-${tabIndex}`,
                          })}
                          onClick={() =>
                            setActiveTab(`${sectionIndex}-${tabIndex}`)
                          }
                          tabIndex="0"
                          href="#"
                        >
                          {"Set " + (tabIndex + 1)}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="tab-content">
                {testMemoSetData.map((tab, tabIndex) => (
                  <div
                    key={"tab-content" + tabIndex}
                    role="tabpanel"
                    className={classnames("tab-pane", {
                      active: activeTab === `${sectionIndex}-${tabIndex}`,
                    })}
                  >
                    <Row>
                      <Col>
                        <RenderAdvSFMTableSection
                          key={`${sectionIndex}-${tabIndex}`}
                          section={section}
                          tabIndex={tabIndex}
                          setData={tab}
                          sectionIndex={sectionIndex}
                          formData={formData}
                          handleFieldChange={handleFieldChange}
                          formErrors={formErrors}
                          addRow={() => {
                            true
                              ? addRow(tab, sectionIndex)
                              : setShowModal(true);
                          }}
                          addColumn={() => addColumn(tab, sectionIndex)}
                          deleteRow={() => deleteRow(sectionIndex)}
                          deleteColumn={(columnIndex) =>
                            deleteColumn(sectionIndex, columnIndex)
                          }
                          groupAssignment={tab.groupAssignment}
                          handleAllSave={handleAllSave}
                          handleCancel={handleCancel}
                          gaData={gaData}
                          setGaData={setGaData}
                          showModalGA={showModalGA}
                          setShowModalGA={setShowModalGA}
                          pageType={pageType}
                          actionClicked={actionClicked}
                          setFormData={setFormData}
                          viewOnly={viewOnly}
                          activeTab={activeTab}
                          allFormulaList={allFormulaList}
                          setIsOverlayLoader={setIsOverlayLoader}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>

              <div className="tab_footer">
                <p>
                  {rolesDetails.map((role, UserIndex) => (
                    <span key={"role-" + UserIndex}>
                      {formData[0]?.sfm_updated_by?.role === role?.role ? role.label : null}
                    </span>
                  ))}{" "}
                  <br />
                  <br /> <span>{formData[0]?.sfm_updated_by?.name}</span>
                </p>
                <p>
                  {rolesDetails.map((role, UserIndex) => (
                    <span key={"role-" + UserIndex}>
                      {formData[0]?.technical_manager?.role === role?.role
                        ? role.label
                        : null}
                    </span>
                  ))}{" "}
                  <br />
                  <br /> <span>{formData[0]?.technical_manager?.name}</span>
                </p>
              </div>
            </div>
          ) : null
        ) : section.type === "list" ? null : (
          <div
            key={"form-config-section" + sectionIndex}
            className="my-2 bg-white"
          >
            <Card className="section_card">
              <CardBody className="section_card_body">
                <CardTitle tag="h5" className="section_title">
                  <div className="list_breadcrumb">
                    {formConfig?.breadcom?.map((title, i) => (
                      <div key={"Form-breadcom" + i}>
                        {i === 0 ? null : (
                          <i className="bi bi-chevron-right card-title-icon"></i>
                        )}{" "}
                        <button
                          className="breadcrumb_button"
                          type="button"
                          onClick={() => navigate(title?.redirect)}
                        >
                          {title.title}
                        </button>
                      </div>
                    ))}
                  </div>
                </CardTitle>

                <Row>
                  {section.fields.map((field, fieldIndex) => {
                    let isShow = true;
                    let isViewOnly = viewOnly;
                    if (!isViewOnly && field.fieldName == "msfm_number") {
                      isShow = false;
                    }
                    if (
                      (field.name === "jrf_remark" &&
                        formData[0]?.jrf_status !== "rejected") ||
                      (field.name === "jrf_status" &&
                        !formData[0]?.jrf_status) ||
                      (field.name === "tm_remarks" &&
                        formData[0]?.status !== "rejected") ||
                      (field.name === "ic_borometric_pressure" &&
                        GetTenantDetails(1, 1) != "TPBPL") ||
                      (field.name === "sfm_borometricpressure" &&
                        GetTenantDetails(1, 1) != "TPBPL")
                    ) {
                      isShow = false;
                    }
                    if (
                      formConfig?.sections[0]?.moduleType === "testmemomain"
                    ) {
                      if (
                        (field.name == "tm_datestartinganalysis" ||
                          field.name == "tm_datecompletion") &&
                        !["results", "certified", "verified"].includes(
                          formData[0]?.["status"]
                        )
                      ) {
                        isShow = false;
                      } else if (field.name == "sample_condition") {
                        if (GetTenantDetails(1, 1) == "TPBPL") {
                          isShow = false;
                        }
                      }
                    } else if (
                      formConfig?.sections[0]?.moduleType === "sampleinward" ||
                      formConfig?.sections[0]?.moduleType === "inwardChecklist"
                    ) {
                      if (
                        (field.name == "smpl_detail_dos" ||
                          field.name == "smpl_detail_recpt_mode") &&
                        !formData[1]?.sampleInwardIdMain
                      ) {
                        isViewOnly = false;
                      } else if (
                        field.name === "jrf_branch_name" &&
                        formData[0]?.jrf_is_external
                      ) {
                        isShow = false;
                      }
                    } else if (formConfig?.sections[0]?.moduleType === "jrf") {
                      if (
                        field.name == "fk_sub_commodity" &&
                        !isRefrenceNoCalled
                      ) {
                        isShow = false;
                      }
                      if (user?.role === "LR") {
                        if (isRegularJRF) {
                          // if (field.name === "jrf_ext_contactpersonemail") {
                          //   isShow = false
                          // }
                        }
                      }
                    } else if (
                      formConfig?.sections[0]?.moduleType ===
                      "internalcertificate"
                    ) {
                      if (
                        field.name == "ic_rejection_remark" &&
                        !["tm-reject", "dtm-reject"].includes(
                          formData[0].status
                        )
                      ) {
                        isShow = false;
                      } else if (field.name === "ic_is_size_analysis") {
                        if (isViewOnly || GetTenantDetails(1, 1) === "TPBPL") {
                          isShow = false;
                        }
                      }
                    } else if (formConfig?.sections[0]?.moduleType === "sfm") {
                      if (
                        field.name == "sfm_remarks" &&
                        formData[0].sfm_status !== "rejected"
                      ) {
                        isShow = false;
                      }
                    }

                    if (
                      [
                        "allotment_msfm_number",
                        "ic_msfm_number",
                        "jrf_msfm_number",
                        "inward_msfm_number",
                        "inward_msfm_number",
                        "tm_msfm_no",
                      ].includes(field.name)
                    ) {
                      if (
                        !["BU"].includes(user?.role) &&
                        !user?.logged_in_user_info?.lab_or_branch
                          ?.lab_is_compliant
                      ) {
                        isShow = false;
                      }
                    }



                    return (
                      isShow && (
                        <div
                          key={"Form-Extra-Adjustments" + fieldIndex}
                          className={"col-md-" + field.width}
                        >
                          <RenderFields
                            field={getCustomCellValues(field)}
                            sectionIndex={sectionIndex}
                            fieldIndex={fieldIndex}
                            formData={formData}
                            handleFieldChange={handleFieldChange}
                            formErrors={formErrors}
                            ////For Group Assignment Only....
                            gaData={gaData}
                            setGaData={setGaData}
                            showModalGA={showModalGA}
                            setShowModalGA={setShowModalGA}
                            ////For Group Assignment Only....
                            ////For Master Data....
                            masterOptions={masterResponse}
                            ////For Master Data....
                            ////For View Status.....
                            viewOnly={isViewOnly}
                            actionClicked={actionClicked}
                            setFormData={setFormData}
                          />
                          {isValideReferenceNo &&
                            field.name === "jrf_referenceno" ? (
                            <p className="text-danger errorMsg">
                              {
                                isValideReferenceNo
                              }
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    );
                  })}
                  <PopupGA
                    showModalGA={showModalGA}
                    setShowModalGA={setShowModalGA}
                    staticData={section.fields}
                  />
                </Row>
                <div className="submitBtn_container">
                  {pageType === "inward" &&
                    !formData[1]?.sampleInwardIdMain && (
                      <button
                        type="button"
                        onClick={(e) =>
                          handleInwardMainSubmit(
                            e,
                            formData,
                            setSaveClicked,
                            setTabOpen,
                            setFormData,
                            setIsOverlayLoader
                          )
                        }
                        className="submitBtn"
                      >
                        Proceed
                      </button>
                    )}
                  {formConfig?.sections[0]?.moduleType ===
                    "sampleverification" &&
                    !istavSaveClicked && (
                      <button
                        type="button"
                        onClick={() => handleSampleVerificationMainSubmit()}
                        className="submitBtn"
                      >
                        {translate("common.saveBtn")}
                      </button>
                    )}
                  {formConfig?.sections[0]?.moduleType === "sfm" &&
                    !viewOnly &&
                    !istavSaveClicked && (
                      <button
                        type="button"
                        onClick={(e) => handleSFMMainSubmit()}
                        className="submitBtn"
                      // disabled={saveClicked}
                      >
                        {translate("common.saveBtn")}
                      </button>
                    )}
                </div>
              </CardBody>
            </Card>
          </div>
        )
      )}
      {formConfig?.sections[0]?.moduleType === "GroupAssignment" ? (
        <GroupAssignmentButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          subTableData={subTableData}
          formData={formData}
          jrfId={jrfId}
          handleBackButtonFunction={handleBackButtonFunction}
          isDisplayNewAddOption={isDisplayNewAddOption}
        />
      ) : formConfig?.sections[0]?.moduleType === "groupAssignmentPreview" ? (
        <GroupAssignmentPreviewButtons
          formData={formData}
          jrfId={jrfId}
          handleBackButtonFunction={handleBackButtonFunction}
        />
      ) : formConfig?.sections[0]?.moduleType === "testmemomain" ? (
        <TestMemoButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          viewOnly={viewOnly}
          role={user?.role}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          testMemoId={testMemoId}
          pageType={pageType}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
        />
      ) : formConfig?.sections[0]?.moduleType === "sampleverification" ? (
        istavSaveClicked && (
          <SampleVerificationButtons
            setIsPopupOpen={setIsPopupOpen}
            setJRFCreationType={setJrfCreationType}
            handleSubmit={handleSubmit}
            saveClicked={saveClicked}
            tableData={subTableData}
            formData={formData}
            viewOnly={viewOnly}
            handleBackButtonFunction={handleBackButtonFunction}
            setSaveClicked={setSaveClicked}
          />
        )
      ) : formConfig?.sections[0]?.moduleType === "allotment" ? (
        <AllotmentButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          saveClicked={saveClicked}
          handleAllotValidate={handleAllotValidate}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
        />
      ) : formConfig?.sections[0]?.moduleType === "sampleinward" ? (
        <SampleInwardButtons
          action={action}
          tabOpen={tabOpen}
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          subTableData={subTableData}
          jrfId={jrfId}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
        />
      ) : formConfig?.sections[0]?.moduleType === "internalcertificate" ? (
        <InternalCertificateButtons
          action={action}
          tabOpen={tabOpen}
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          subTableData={subTableData}
          jrfId={jrfId}
          viewOnly={viewOnly}
          handleSubmit={handleSubmit}
          remarkText={remarkText}
          setSaveClicked={setSaveClicked}
          formConfig={formConfig}
          saveClicked={saveClicked}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
          isValideValue={isValideValue}
        />
      ) : formConfig?.sections[0]?.moduleType === "inwardChecklist" ? (
        <ViewCheckListButtons
          remarkText={remarkText}
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          setSaveClicked={setSaveClicked}
          formConfig={formConfig}
          saveClicked={saveClicked}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
        />
      ) : formConfig?.sections[0]?.moduleType === "sfm" ? (
        istavSaveClicked && (
          <SFMButtons
            setIsPopupOpen={setIsPopupOpen}
            setJRFCreationType={setJrfCreationType}
            handleSubmit={handleSubmit}
            saveClicked={saveClicked}
            formData={formData}
            viewOnly={viewOnly}
            testMemoSetData={testMemoSetData}
            handleBackButtonFunction={handleBackButtonFunction}
          />
        )
      ) : formConfig.listView ? null : (
        !viewOnly && (
          <JRFButtons
            setIsPopupOpen={setIsPopupOpen}
            setJRFCreationType={setJrfCreationType}
            handleSubmit={handleSubmit}
            viewOnly={viewOnly}
            handleBackButtonFunction={handleBackButtonFunction}
          />
        )
      )}
      {isPopupOpen && openDeletePopup()}
      {isRejectPopupOpen && openRejectModal()}

      {formConfig.listView ? (
        <Card>
          <CardBody className="list_body">
            <CardTitle tag="h5" className="list_title">
              <div className="list_breadcrumb">
                {formConfig?.breadcom?.map((title, i) => (
                  <span key={"form-breadcom-main-" + i}>
                    {i === 0 ? null : (
                      <i
                        className="bi bi-chevron-right card-title-icon"
                        key={"form-breadcom-icon" + i}
                      ></i>
                    )}{" "}
                    {title.title}
                  </span>
                ))}
              </div>

              <div className="jrf_container">
                <div className="jrf_container_btns">
                  <div className="formSearch">
                    <i className="bi bi-search"></i>
                    <input
                      type="search"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => searchAPI(e.target.value)}
                    />
                  </div>

                  <button
                    type="button"
                    className="searchby_button"
                    onClick={() => setSearchby(!searchby)}
                  >
                    {translate("common.searchReportBtn")}
                  </button>

                  {isFilterBtnClicked && (
                    <>
                      <button
                        type="button"
                        className="searchby_button"
                        onClick={() => clearFilterData()}
                      >
                        Clear Filter
                      </button>
                      {(["sampleinward", "sampleverification"].includes(
                        formConfig.listView?.moduleType
                      ) ||
                        (formConfig.listView?.moduleType === "allotment" &&
                          CommonTMRoles.includes(user?.role))) && (
                          <button
                            type="button"
                            className="searchby_button"
                            onClick={() => getAllListingDataExports()}
                          >
                            Export
                          </button>
                        )}
                    </>
                  )}

                  {["jrf"].includes(formConfig.listView?.moduleType) &&
                    isModuelePermission(
                      rolePermissions,
                      formConfig.listView?.moduleType,
                      "add"
                    ) ? (
                    <button
                      type="button"
                      onClick={() =>
                        navigate(
                          user?.role === "LR"
                            ? "/jrfListing/external-jrf"
                            : "/jrfListing/jrf"
                        )
                      }
                      className="create_button"
                    >
                      <i className="bi bi-plus-lg"></i>
                      {translate("common.createBtn")}
                    </button>
                  ) : null}
                </div>

                {searchby && (
                  <PopupSearch
                    setSearchby={setSearchby}
                    searchConfigJson={searchConfigJson}
                    filterIndex={filterIndex}
                    handleSearchFilter={handleSearchFilter}
                    searchFormData={searchFormData}
                    searchFormDataType={searchFormDataType}
                    setFilterIndex={setFilterIndex}
                    setSearchFormDataErros={setSearchFormDataErros}
                    searchFormDataErros={searchFormDataErros}
                    setSearchFormData={setSearchFormData}
                    getAllListingData={getAllListingData}
                    setisFilterBtnclicked={setIsFilterBtnClicked}
                    user={user}
                  />
                )}
              </div>
            </CardTitle>
            {isModuelePermission(
              rolePermissions,
              formConfig.listView?.moduleType,
              "view"
            ) && (
                <div className="ag-theme-quartz" id="myGrid">
                  {" "}
                  <RenderListSection
                    section={formConfig.listView}
                    sectionIndex={1}
                    actions={formConfig.listView.actions}
                    responseData={response}
                    getAllListingData={getAllListingData}
                    formConfig={formConfig}
                    statusCounts={statusCounts}
                    setIsRejectPopupOpen={setIsRejectPopupOpen}
                    setJRFCreationType={setJrfCreationType}
                    setIsPopupOpen={setIsPopupOpen}
                    loadingTable={loadingTable}
                    setIsOverlayLoader={setIsOverlayLoader}
                  />
                </div>
              )}
          </CardBody>
        </Card>
      ) : null}
    </form>
  ) : (
    <Loading />
  );
};

Forms.propTypes = {
  formConfig: PropTypes.object,
  masterResponse: PropTypes.object,
  getSampleIdsMasterData: PropTypes.func,
  searchConfigJson: PropTypes.object,
  getAssignmentMasterData: PropTypes.func,
  setTestMemoId: PropTypes.func,
  testMemoId: PropTypes.string,
  testReportPrweview: PropTypes.bool,
  isExternalJRF: PropTypes.bool,
  isRegularJRF: PropTypes.bool,
  totalSamples: PropTypes.number,
};

export default Forms;
